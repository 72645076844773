import {useContext, useEffect, useState, createContext} from 'react'
import PropTypes from 'prop-types'
import {initializeApp} from 'firebase/app'
import {getMessaging, getToken, onMessage, deleteToken} from 'firebase/messaging'
import storage from 'utils/storage'
FirebaseProvider.propTypes = {
  children: PropTypes.node
}

const FirebaseContext = createContext({})

export function FirebaseProvider({children}) {
  const [fcmNotification, setFcmNotification] = useState(null)

  // Initialize Firebase
  const firebase = initializeApp({
    apiKey: 'AIzaSyCmHCYYypCgD7eqNHFvXD9FLZPjTcvy8Q8',
    authDomain: 'test-database-b93e0.firebaseapp.com',
    projectId: 'test-database-b93e0',
    storageBucket: 'test-database-b93e0.appspot.com',
    messagingSenderId: '369827044916',
    appId: '1:369827044916:web:573b8a119ebacd3b56b461',
    measurementId: 'G-8M7KHDNY4F'
  },)
  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(firebase)

  const getFcmToken = async () => {
    // todo : add APNs here for safari mobile compatibility
    try {
      // Check if the browser is Safari
      if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
        window.safari.pushNotification.requestPermission(
          'com.starjobsapps', // Your website's URL
          'C86586K3W4', // Your Website Push ID from Apple Developer
          {},
          (permissionData) => {
            if (permissionData.permission === 'granted') {
              const currentToken = permissionData.deviceToken;
              console.log('Safari device token:', currentToken);
              return currentToken
              // Send the deviceToken to your server and use it to send notifications via FCM
            }
          }
        );
      } else {
        const currentToken = await getToken(messaging, {vapidKey: 'BMmNF0CKsg3MKf5PrT5NsrJlX0SXTT7hcmTVzCG2z_TSNBNwkbo_hUSdN-pb0Jd1yTa4trSOGdKicUenYzhxDzM'})
        if (currentToken) {
          console.log('FCM Token:', currentToken)
          return currentToken
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }
    } catch (err) {
      console.error('An error occurred while retrieving token.', err)
    }
    return
  }

  const deleteFirebaseToken = async (fcm) => {
    try {
      if (fcm) {
        await deleteToken(messaging, {token: fcm})
        console.log('Token deleted successfully')
      } else {
        console.log('No registration token available to delete.')
      }
    } catch (err) {
      console.error('An error occurred while deleting token. ', err)
    }
  }

  const onMessageListener = new Promise((resolve, reject) => {
      onMessage(messaging, (payload) => {
        // const user = JSON.parse(await storage.getUser())
        resolve(payload)
        setFcmNotification(payload)
        // const { body } = payload.notification;
        // console.log('eto oh: ', user.accountType)
      })
  })

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        fcmNotification,
        getFcmToken,
        deleteFirebaseToken,
        onMessageListener
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => {
  return useContext(FirebaseContext)
}
