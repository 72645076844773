import React, { useEffect, useState } from 'react'
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  DialogTitle,
  CardMedia,
  CardContent,
  CardActions,
} from '@material-ui/core'
import YouTube from 'react-youtube';
// components
import Page from '../components/Page'

import userAPI from 'utils/api/users'
import storage from 'utils/storage'


export default function VideoTutorial() {

  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [title, setTitle] = useState()
  const [youtubeId, setYoutubeId] = useState()
  const [description, setDescription] = useState()
  const [announcmentId, setAnnouncementId] = useState()
  const [videos, setVideos] = useState([])
  const [videoId, setVideoId] = useState()
  
  const load = async () => {
    const local_user = await storage.getUser()
    if (!local_user) return

    const user = JSON.parse(local_user)
    const videos = await userAPI.get_videos_tutorial(user.company)
    if(videos.ok){
      setVideos(videos.data.body)
    }
    else {
      setVideos([])
    }
    setUsers(user)
  }

  useEffect(() => {
    load()
  }, [])
  const handleClose = () => {
    setOpen(false)
  }

  const handleUpload = async () => {
    if (!title) {
      alert("Please enter title")
    }
    else {
        const data ={
            title: title,
            youtubeId: youtubeId,
            description: description,
            uid: users._id,
            store: users.company
        }

        const upload = await userAPI.post_videos_tutorial(data)
        if(upload){
            load()
            window.alert("Successfully added")
            setOpen(false) 
        }
    }
  }
  const handleOpenUpdate = async (e) => {
    const id = e.target.value
    videos.map(data =>{
        if(data._id === id){
            setTitle(data.title)  
            setYoutubeId(data.youtubeId)  
            setDescription(data.description)
            setVideoId(id)
            setOpenEdit(true)
        }
    })
  }
  const handleCloseEdit = () => {
    setOpenEdit(false)
  }
  const handleEdit = async () => {
    const data = {
      title: title,
      youtubeId: youtubeId,
      description: description
    }
    const r = await userAPI.edit_video_tutorial(videoId, data)
    if (r.status === 200) {
      load()
      alert("Video updated")
      setOpenEdit(false)
    }
  }
  const handleOpenDelete = (e) => {
    setVideoId(e.target.value)
    setOpenDelete(true)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  const handleDelete = async (e) => {
    const r = await userAPI.delete_video_tutorial(videoId)
    if(r.status === 200) {
      await load()
      alert("Video deleted")
      setOpenDelete(false)
    }
    else {
      alert(r.message)
      setOpenDelete(false)
    }
    
  }

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Page title="User | Time In">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Videos
          </Typography>
          <Button variant="contained" onClick={setOpen}>New Video</Button>
        </Stack>
          
          {videos.length > 0 
            ?
              <>
                {videos.map(d => {
                    return (
                      <Card sx={{ width: "100%", mt: 5 }}>
                        <CardMedia
                          sx={{ height: 100 }}
                          image={d.img}
                          title="green iguana"
                        />
                        <CardContent>
                          <YouTube videoId={d.youtubeId} opts={opts} />
                          <Typography gutterBottom variant="h5" component="div">
                            {d.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {d.description ? d.description : "No description"}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="medium" value={d._id} onClick={(e) => handleOpenUpdate(e)}>Edit</Button>
                          <Button size="small" value={d._id} onClick={(e) => handleOpenDelete(e)}>Delete</Button>
                        </CardActions>
                      </Card> 
                    )  
                  })
                }
              </>
               
            :
              <Card>
                <img
                  src="http://com-sparkle-sparkle.herokuapp.com/static/media/nothing.108effa8.svg"
                  loading="lazy"
                />
              </Card>  
          }
        
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New Video</DialogTitle>
          <DialogContent>
            <TextField
              error
              autoFocus
              margin="dense"
              id="name"
              label="Youtube Title"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e)=>{setTitle(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Youtube Id"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setYoutubeId(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setDescription(e.target.value)}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpload }>Upload</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Video -> {title}</DialogTitle>
          <DialogContent>
            <TextField
              error
              autoFocus
              margin="dense"
              id="name"
              label={title}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e)=>{setTitle(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label={youtubeId}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setYoutubeId(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label={description ? description : "Description"}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setDescription(e.target.value)}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleEdit}>Save</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Do you wish to proceed with this action?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Once action processed you may not be able to retrieve the data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              No
            </Button>
            <Button onClick={handleDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  )
}
