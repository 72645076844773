import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { styled } from '@mui/material/styles';
import { Icon } from "@iconify/react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import user_api from 'utils/api/users';
import storage from 'utils/storage';
const moment = require('moment-timezone')
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


export default function AllShift() {
    const [switchState, setSwitchState] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [weeklyRecord, setWeeklyRecord] = useState([]);
    const [user, setUser] = useState()
    const [time, setTime] = useState({
        time: '',
        label:''
    })
    const renderTime = (_time) => {
      let _date = new Date(_time)
  /*    var hours = _date.getHours()
      var minutes = _date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      var strTime = hours + ':' + minutes + ' ' + ampm
      const phTime = moment.tz(strTime, 'h:mm A', 'Asia/Manila')*/
      const userTimeZone = moment.tz.guess()
      const userLocalTime = _date.toLocaleString('en-US', { timeZone: userTimeZone, hour: 'numeric', minute: '2-digit' });
      return userLocalTime
      //return strTime
    }

    const filterByStatus = (_data, type) => {
      let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
      if (_d.length > 0) {
        _d = _d[0]
      }
      if (_d) {
        if(typeof(_d.time) === "string") {
          return {
            time: _d.time === undefined ? 'n/a' : _d.time,
            _longitude: _d.location === undefined ? '' : _d.location.longitude,
            _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
            workmate: _d.workmate === undefined ? '' : 'Workmate( ' +_d.workmate + ' )'
          }
        }
        else {
          return {
            time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
            _longitude: _d.location === undefined ? '' : _d.location.longitude,
            _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
            workmate: _d.workmate === undefined ? '' : 'Workmate( ' +_d.workmate + ' )'
          }   
        }   
      } else {
        return '-'
      }
    }  
    const load = async () => {
        const getUser = await storage.getUser()
        const user = JSON.parse(getUser)
        if(!user._id) {
            alert("Something went wrong please try again")
        }
        else {
            const result = await user_api.get_limited_users(user._id)
            setWeeklyRecord(result.data)
            setUser(user)    
        }

    }    
    const handleChangeDate = async (date) => {
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      setStartDate(date)
      const result = await user_api.get_users_bydate(user._id, fomattedDate)
      setWeeklyRecord(result.data)
    }
    useEffect(() => {
      load()
    }, [])
    return (
        <>
            <Box sx={{padding:'1rem'}}>
                <div style={{paddingBottom: ".5em"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                
                <MobileDatePicker
                    label="Choose Date"
                    value={startDate}
                    minDate={new Date('2017-01-01')}
                    renderInput={(params) => <TextField {...params}/>}
                    onChange={(date:Date) => handleChangeDate(date)}
                    disableTextEditor
                />
                </LocalizationProvider>
            </div>
            </Box>
            {weeklyRecord.length > 0 
                ?
                    <>
                        {weeklyRecord.map((record,key) => {
                            const formattedDate = moment(record.date).format("ddd MMM DD");
                            return(
                                <> 
                                    <Typography variant="caption" style={{margin: 0}}>{formattedDate}</Typography>  
                                    <Box>
                                        <Box
                                            border={1}
                                            borderRadius={2}
                                            sx={{
                                                overflowX: 'auto',
                                                display: 'flex',
                                                gap: '.5rem',
                                                padding: '.5rem',
                                                margin: 'auto',
                                                justifyContent: 'center',
                                                width: '100%'
                                            }}>
                                                  
                                            <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                                                <Typography variant='caption'>Timein</Typography>
                                                <br/>
                                                <Typography variant="caption" sx={{ fontSize: '.6rem' }}>{filterByStatus(record.record, 'time-in').time}</Typography>   
                                            </Box>
                                            <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                                                <Typography variant='caption' >BreakIn</Typography>
                                                <br/>
                                                <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{filterByStatus(record.record, 'break-in').time}</Typography>
                                            </Box>
                                            <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                                                <Typography variant='caption' >BreakOut</Typography>
                                                <br/>
                                                <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{filterByStatus(record.record, 'break-out').time}</Typography>
                                            </Box>
                                            <Box sx={{ alignContent: 'center', textAlign: 'center' }}>
                                                <Typography variant='caption'>TimeOut</Typography>
                                                <br/>
                                                <Typography variant="caption"  sx={{ fontSize: '.6rem' }}>{filterByStatus(record.record, 'time-out').time}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>       
                            )    
                        })}
                    </>
                :
                    ''
            }
        </>
    )
}
