import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Box component="img" src="/favicon/logo.jpg" sx={{ width: 60, height: 60, ...sx }} />
      <Typography variant="h6" gutterBottom style={{ color: 'Black' }}> SPARKLE TIMEKEEPING</Typography>
    </div>
  )
}
