import { Card, CardContent, Typography, Avatar, Stack, Button, Box } from '@material-ui/core';
import Label from './Label';
import { UserMoreMenu } from './_dashboard/user';

export default function UserCard({ user, handleGenerateQR, handleAction }) {
  const { _id, firstName, displayName, lastName, email, phone, role, isOnBoarded, company, image, isVerified } = user;
  let name = `${firstName} ${lastName}`;
  if (firstName === null || lastName === null) {
    name = displayName;
  }

  return (
    <Card key={_id} sx={{ mb: 2 }}>
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={name} src={image} />
          <Typography variant="h6" component="div">
            {name}
          </Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {email}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {company}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {phone}
        </Typography>
        <Box display="flex" justifyContent="space-between">
            <Label variant="ghost" color={'info'}>
            Role: {role === 1 ? 'Store' : 'Employee'}
            </Label>
            <Label variant="ghost">
            Verified: {isVerified ? 'Yes' : 'No'}
            </Label>
            <Label variant="ghost" color={!isOnBoarded ? 'error' : 'success'}>
            Onboarded: {isOnBoarded ? 'Complete' : 'Incomplete'}
            </Label>

        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button size="small" id={_id} value={name} onClick={(e) => handleGenerateQR(e)}>Generate QR</Button>
          <UserMoreMenu id={_id} action={handleAction} />
        </Box>
      </CardContent>
    </Card>
  );
}
