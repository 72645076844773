import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
import { Icon } from '@iconify/react'
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  Slide,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  CircularProgress
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import eyeFill from '@iconify/icons-eva/eye-fill'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import userLight from '@iconify/icons-ph/user-light';
import { LoadingButton } from '@material-ui/lab'
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import CredentialLoginIcon from 'assets/svg/loginSwitchedCred.png'
import storage from 'utils/storage'
import useAuth from 'utils/api/auth'
import Bugsnag from '@bugsnag/js'
import userApi from 'utils/api/users'
import QrReader from 'react-qr-reader'
// ----------------------------------------------------------------------

export default function LoginForm({ currentStep, handleEmailForm }) {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [openVerification, setOpenVerification] = useState(false)
  const [openPassword, setOpenPassword] = useState(false)
  const [openQR, setOpenQR] = useState(false)
  const [openCreateAccount, setOpenCreateAccount] = useState(false)
  const [scanMessage, setScanMessage] = useState('No data found')

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().min(11, 'Not a valid phone number! (ex. 091523468790)').max(11, 'Not a valid phone number! (ex. 091523468790)'),
  })

  const formik = useFormik({
    initialValues: {
      phone: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!values.phone) {
        alert("Please input mobile no.")
        setLoading(false)
      }
/*      else if (!values.password) {
        alert("Please input password.")
        setLoading(false)
      }*/
      else {
        const items = {
          "phone": values.phone,
          "password": values.password
        }
        const result = await useAuth.sign_in_phone(values.phone)
        console.log(result)
        if (!result.ok) {
          alert(result.data.msg)
          return setLoading(false)
        }
        else 
        {
          let { data } = result
          if (data.role === 0) {
            data.sid = data.store_id
          }
          if (!JSON.parse(data.isOnBoarded)) {
            setLoading(false)
            return navigate(`/${data.sid}/onboard`)
          }
          resetForm()
          await storage.storeUser(data)
          await storage.storeToken(data.token)
          setLoading(false)
          if (data.role === 1 || data.role === 4) return navigate(`/stores/app`, { replace: true })
          return navigate(`/dashboard/app`, { replace: true })  
        } 
      }
      
      

/*      let { data } = result
      data.sid = data.store_id
      await storage.storeUser(data)
      await storage.storeToken(data.token)
      setLoading(false)
      if (data.verificationCode === null) {
        if (data.isVerified && data.isOnBoarded) {
          if (data.role === 1 || data.role === 4) return navigate('/stores/app')
          navigate(`/dashboard/app`)
        } else if (data.isVerified && !data.isOnBoarded) {
          navigate(`/${data.store_id}/onboard`)
        } else if (!data.isVerified && !data.isOnBoarded) {
          navigate(`/${data.store_id}/onboard`)
        }
      } else {
        currentStep(2)
      }*/
    },
  })

  const { errors, touched, values, handleSubmit, getFieldProps, resetForm } = formik
  useEffect(() => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection) {
      const { type, effectiveType, downlink } = connection;
      if(downlink < 0.5) {
        alert("Slow internet connection please wait")
      }
    }
  }, []);
  useEffect(() => {

    const interval = setInterval(() => {

      if (!navigator.onLine) {
        alert('You are offline. Please check your internet connection.');
      }

    }, 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOpenVerificationModal = async () => {
    setLoading(true)
    const data = {
      "email": email
    }
    const result = await userApi.set_verification_code(data)
    if (result.data.success) {
      alert("Please check verification code sent to the email address")
      setOpenVerification(true)
      setOpen(false)
    }
    else {
      alert(result.data.msg)
    }
    setLoading(false)
  }

  const handleCloseVerificationModal = () => {
    setOpenVerification(false)
    setOpen(false)
  }

  const handleSubmitVerification = async () => {
    setLoading(true)
    const data = {
      "email": email,
      "token": code
    }
    const result = await userApi.get_verification_code(data)
    if (result.data.success) {
      setOpenPassword(true)
      setOpenVerification(false)
      setOpen(false)
    }
    else {
      alert(result.data.msg)
      setOpenPassword(false)
    }
    setLoading(false)
  }

  const handleNewPassword = async () => {
    setLoading(true)
    if(!password) {
      alert("Please input new password")
    }
    else {
      const data = {
        "email": email,
        "password": password
      }
      const result = await userApi.set_new_password(data)
      if (result.status === 200) {
        alert("Password successfuly updated")
        setOpenPassword(false)
        setOpenVerification(false)
        setOpen(false)
      }
      else {
        alert("Something went wrong please contact administrator")
      }
    }
    setLoading(false)
    
  }

  const handleClosePassword = () => {
    setOpenPassword(false)
    setOpenVerification(false)
    setOpen(false)
  }

  const handleScan = async (res) => {
    const url = res?.link;
    
    if (url) {
      window.location.href = url;
    } else {
      alert("No link found in the response.");
    }
  };

  const handleCloseQR= async () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleOpenCreateAccount = async () => {
    setOpenCreateAccount(true)
  }

  const handleCloseCreateAccount= async () => {
    setOpenCreateAccount(false)
  }


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{paddingLeft:'1.5rem', paddingRight:'1.5rem'}}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="tel"
            inputProps={{ maxLength: 11, minLength: 11 }}
            label="Mobile number"
            autoFocus
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            InputProps={{
              sx: {
                borderRadius: 28,
                backgroundColor: '#F6F6F6',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.30)', // Adjust the shadow values
              },
              startAdornment: (
                <InputAdornment position="center">
                   <IconButton position="center"> 
                      <LocalPhoneTwoToneIcon />
                   </IconButton>
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position="end">
              //       <div
              //         style={{
              //           width: '40px',
              //           height: '40px',
              //           border: '3px solid #1F98E8',
              //           borderRadius: '50%',
              //           overflow: 'hidden',
              //         }}
              //         onClick={() => handleSwitchForm(false)}
              //       >
              //         <img
              //           width="100%"
              //           height="100%"
              //           style={{ filter: 'invert(60%) sepia(100%) saturate(300%) hue-rotate(180deg)' }}
              //           src={CredentialLoginIcon}
              //           alt="custom-icon"
              //         />
              //       </div>
              //   </InputAdornment>
              // )
            }}
          />
{/*          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              sx: {
                borderRadius: 28,
                backgroundColor: '#F6F6F6',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.30)', // Adjust the shadow values
              },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton position='start'>
                    <LockOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />*/}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
            sx={{ fontSize:'.5rem',color: '#1F98E8' }}
          />
         
          <Link onClick={() => setOpen(true)} variant="subtitle2" to="#" style={{ color: '#1F98E8' }}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton 
        fullWidth 
        size="large" 
        type="submit" 
        variant="contained" 
        loading={isLoading} 
        loadingIndicator={<CircularProgress color="primary" />}
        style={{ 
          borderRadius: 28, 
          color:'white',
          backgroundColor:'#1F98E8', 
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.40)', }}>
          Login
        </LoadingButton>
        <Typography
            onClick={() => handleEmailForm()} 
            variant='body1' 
            color='#1F98E8' 
            sx={{textAlign:'center', mt:'1rem'}}> 
            Login using credentials
          </Typography>
          <Typography
            variant='body1' 
            onClick={handleOpenCreateAccount} 
            color='#1F98E8' 
            sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', mt: 1 }}
          > 
            {/*<QrCodeScannerIcon />*/}
            Create an account
          </Typography>
        </Box>
      </Form>
      <Dialog
        open={open}
        onClose={handleCloseModal}
      >
        <DialogTitle>Forgot Password?
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter registered email address you used to sign-up.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            variant="standard"
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          {isLoading ? <Button onClick={handleOpenVerificationModal} disabled>Submit</Button> : <Button onClick={handleOpenVerificationModal}>Submit</Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openVerification}
        onClose={handleCloseVerificationModal}
      >
        <DialogTitle>Verification Code
          <IconButton
            aria-label="close"
            onClick={handleCloseVerificationModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter verification code sent through your email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Verification Code"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVerificationModal}>Cancel</Button>
          {isLoading ? <Button onClick={handleSubmitVerification} disabled>Submit</Button> : <Button onClick={handleSubmitVerification}>Submit</Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPassword}
        onClose={handleClosePassword}
      >
        <DialogTitle>New Password
          <IconButton
            aria-label="close"
            onClick={handleClosePassword}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePassword}>Cancel</Button>
          {isLoading ? <Button onClick={handleNewPassword} disabled>Submit</Button> : <Button onClick={handleNewPassword}>Submit</Button>}
        </DialogActions>
      </Dialog>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
            <>
              <QrReader
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: '250px', heigth: '200px' }}
              // className={'qrScanner'}
              />
              <DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>
            </>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCreateAccount}
        onClose={handleCloseCreateAccount}
        maxWidth="xs" // Adjusting width to make it similar to the image
        fullWidth
      >
        <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
          <Box 
             sx={{ 
               display: 'flex', 
               alignItems: 'center', 
               justifyContent: 'center', 
               mb: 2 
             }}
           >
             <img src="/favicon/logo.jpg" alt="Sparkle Timekeeping" style={{ width: 100 }} />
             <Typography variant="h6" fontWeight="bold" sx={{ ml: 2 }}>
               SPARKLE TIMEKEEPING
             </Typography>
           </Box>
        </DialogTitle>

        <DialogContent sx={{ textAlign: 'center', px: 5, py: 3 }}>
          <Box
            sx={{
              backgroundColor: '#f8f9fa',
              borderRadius: 2,
              p: 3,
              boxShadow: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              Create Employee Account
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Scan store QR code to proceed
            </Typography>
            <Box 
              sx={{ 
                mb: 2, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}
            >
              <img
                src="https://media.istockphoto.com/id/1358621997/vector/qr-code-smartphone-scanner-linear-icon-vector-illustration.jpg?s=612x612&w=0&k=20&c=ePiWZHIbseW9GwmM498rRKC_Dvk8IsKv41nqnC8iZhQ="
                alt="QR code"
                style={{ width: 150 }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setOpenQR(true)}
              sx={{ textTransform: 'none', px: 5 }}
            >
              Scan Now
            </Button>
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', flexDirection: 'column', py: 2 }}>
          {/* Sign-in link */}
          <Typography variant="body2" sx={{ mb: 2 }}>
            Already have an account?{' '}
            <Button 
              color="primary" 
              sx={{ textTransform: 'none', p: 0 }} 
              onClick={() => window.location.href = '/login'}
            >
              Sign in here
            </Button>
          </Typography>

          {/* Create Store Account button */}
          <Stack>
            <Button
              variant="text"
              color="primary"
              sx={{ textTransform: 'none' }}
              onClick={() => window.location.href = '/store/create'}
            >
              Create Store Account
            </Button>
          </Stack>

          {/* Close Modal Button */}
          <Button
            onClick={handleCloseCreateAccount}
            variant="outlined"
            color="error"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  )
}
