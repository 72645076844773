import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Icon } from '@iconify/react'
import helpIcon from '@iconify/icons-eva/question-mark-circle-outline'
import DiscordDialog from './DiscordDialog'

export default function HelpButton() {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ display:'flex', justifyContent:'center', alignItems:'center',width: '100%', height:'8vh' }}>
      {/*<img src="https://i.imgur.com/Xn8GaXP.png" alt="Image" style={{height: "35%", "margin-right": ".5rem"}} />*/}
      <a
        size="small"
        color="inherit"
        variant="text"
        endIcon={<Icon icon={helpIcon} />}
        target="_blank"
        href="https://docs.google.com/document/d/1P7V38LsyHa5PdG52xnk25uMou9aITA9ZD0bN_Q_-RRM"
        style={{ color: '#1F98E8', textDecoration: 'none' }}
      >
        
      </a>
      <DiscordDialog open={open} handleClose={handleClose} />
    </div>
  )
}
