import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useSnackbar } from 'notistack5'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
import { alpha, styled } from '@material-ui/core/styles'
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core'
import { MHidden } from '../../components/@material-extend'
//
import AccountPopover from './AccountPopover'
// import NotificationsPopover from './NotificationsPopover'
import storage from 'utils/storage'
import { SocketContext } from 'utils/context/socket'
import Logo from 'components/Logo'
// import {UsersContext} from 'utils/context/users'
const DRAWER_WIDTH = 0
const APPBAR_MOBILE = 40
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}))



const DashboardNavbar = ({ onOpenSidebar, _data }) => {
  const socket = useContext(SocketContext)
  const { enqueueSnackbar } = useSnackbar()
  // const {users, setUsers} = useContext(UsersContext)
  const [data, setData] = useState({
    users: [],
    employee: [],
    loading: false,
    online: 0,
  })

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    backgroundImage: data.users.role === 4 || data.users.role === 1 ? 'none' : (isHeaderVisible ? 'none' : `url('/favicon/BACKGROUND.svg')`), // Use the image URL within the backgroundImage property
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: 'white', // Text color
    height: '70px',
    width: '100%',
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }))

  const load = async () => {
    setData((p) => ({ ...p, loading: true }))
    const local_user = await storage.getUser()
    if (!local_user) return;
    const user = JSON.parse(local_user)
    let { _id, role } = user
    if (role === 1) {
      socket.emit('get_users', { _id })
    }
    return setData((p) => ({ ...p, users: user, loading: false }))
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledDown = currentScrollPos > 10;

      setIsHeaderVisible(isScrolledDown);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
/*  useEffect(() => {
    socket.on('notification', async (notif) => {
      const local_user = await storage.getUser()
      if (!local_user) return;
      const user = JSON.parse(local_user)
      if (user.role === 1 || user.role === 4) {
        let { _id } = user
        socket.emit('get_users', { _id })
        enqueueSnackbar(notif?.description, {
          variant: 'info',
        })
      }
    })

    socket.on('receive_status', async (notif) => {
      const local_user = await storage.getUser()
      if (!local_user) return;
      const user = JSON.parse(local_user)
      if (user.role === 1 || user.role === 4) {
        let { _id } = user
        socket.emit('get_users', { _id })
        enqueueSnackbar(`${notif?.user} - ${notif?.status}`, {
          variant: 'info',
        })
      }
    })

    socket.on('users', async (users) => {
      if (!users) return console.log('error fetching users')

      const local_user = await storage.getUser()
      if (!local_user) return console.log('error current user')

      const index = users.findIndex((user) => user.uuid === user._id)
      if (index !== -1) {
        return users.splice(index, 1)[0]
      }

      return setData((p) => ({ ...p, online: users.length }))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, enqueueSnackbar])*/
  return (
    <RootStyle>
      <ToolbarStyle sx={{borderRadius: '0 0 20px 20px'}}>
        {!isHeaderVisible
          ? 
            (
              <>
                {data.users.role === 0
                  ? 
                    <div style={{display:'flex',justifyContent:'center', alignItems:'center', width:'90%'}}>
                      <img src="/favicon/dashboard.png" alt="Your Image" style={{width: "20%", margin: "0px 0px 0px 20px", height: "50px", width: "50px"}}/>
                      <label style={{textAlign: 'center', color: 'white', fontFamily: 'Goldman', fontWeight: '400', wordWrap: 'break-word'}}><Box fontWeight='fontWeightMedium' display='inline'>SPARKLE TIME-KEEPING</Box></label>
                    </div>
                  : 
                    ''
                }

              </>
            ) 
          : 
            (
              ''
            )
        }
        {data.users.role === 1 || data.users.role === 4 ? (
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} /> 
              <h5 style={{ color: '#727272' }}>
                Menu
              </h5>
            </IconButton>
          </MHidden>
        ) : (
          ''
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {data.users.role === 1 || data.users.role === 4 ? (
            <>
              <h5 style={{ color: '#727272' }}>
                {data.online} Online {data.online && data.online > 0 ? 'Employees' : 'Employee'}
              </h5>
              {/* <NotificationsPopover /> */}
            </>
          ) : (
            ''
          )}

          <AccountPopover user={data.users} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}

export default DashboardNavbar

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
