import React from 'react'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
// import editFill from '@iconify/icons-eva/edit-fill'
import archiveOutline from '@iconify/icons-eva/archive-outline'
import clinicalNotesOutline from '@iconify/icons-material-symbols/clinical-notes-outline'
import { Link as RouterLink } from 'react-router-dom'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import fileDoneOutlined from '@iconify/icons-ant-design/file-done-outlined';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Label,
  Box,
  FormControl,
  Select,
  InputLabel,
  Paper,
  Grid,
  FormControlLabel,
  FormGroup,

} from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";


import { useSnackbar } from 'notistack5'

//api

import useUser from 'utils/api/users'
import storage from 'utils/storage'

// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function UserMoreMenu({ id, action }) {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [openTimekeep, setOpenTimekeep] = useState(false)
  const [openArchive, setOpenArchive] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [users, setUsers] = useState([])
  const [months, setMonths] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
  const [days, setDays] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"])
  const [month, setMonth] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true)
    setIsOpen(false)
  }

  const handleOpenTimeRecord = () => {
    setOpenTimekeep(true)
    setIsOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
    setOpen(false)
  }

/*  const handleClickOpenArchive = () => {
    setOpenArchive(true)
    setIsOpen(false)
  }

  const handleCloseArchive = () => {
    setOpenArchive(false)
    setOpen(false)
  }*/

/*  const handleOpenHistory = async () => {
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    const record = await useUser.get_user_records_range_v2(id, )
    setOpenHistory(true)
  }

  const handleCloseHistory = async () => {
    setOpenHistory(false)
  }*/

/*  const handleRemove = async () => {
    const local_user = await storage.getUser()

    if (!local_user) {
      setOpen(false)
      return enqueueSnackbar('Unable to process action', { variant: 'error' })
    }

    if (!id) {
      setOpen(false)
      return enqueueSnackbar('Unable to remove the user', { variant: 'error' })
    }

    const user = JSON.parse(local_user)
    const result = await useUser.remove_user(user._id, id)
    if (!result.ok) return enqueueSnackbar(result.data.msg, { variant: 'error' })

    enqueueSnackbar('User deleted success', { variant: 'success' })
    setOpen(false)
    window.location.reload()
  }*/

  const handleArchive = async () => {
    const local_user = await storage.getUser()
    if (!local_user) {
      setOpenArchive(false)
      return enqueueSnackbar('Unable to process action', { variant: 'error' })
    }

    if (!id) {
      setOpenArchive(false)
      return enqueueSnackbar('Unable to remove the user', { variant: 'error' })
    }
    const user = JSON.parse(local_user)
    const result = await useUser.archive_user(user._id, id)
    if (!result.ok) return enqueueSnackbar(result.data.msg, { variant: 'error' })
    action("success")
    enqueueSnackbar('User archived success', { variant: 'success' })
    setOpen(false)
    /*window.location.reload()*/
    setOpenArchive(false)
  }

  const handleChange = async (e) => {
    console.log("Test")
    setMonth(e.target.value);
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
{/*        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" onClick={handleClickOpen} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>*/}

        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={archiveOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Archive"
            onClick={handleClickOpen}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
       {/* <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={clinicalNotesOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Set Time Record"
            onClick={handleOpenTimeRecord}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>*/}
{/*        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={fileDoneOutlined} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Records"
            onClick={handleOpenHistory}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>*/}
      </Menu>

      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Do you wish to proceed with this action?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Once action processed you may not be able to retrieve the data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleArchive} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openTimekeep}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Timekeeping!'}</DialogTitle>
          <DialogContent>
            <Box sx={{ minWidth: 120, mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  label="Month"
                  onChange={handleChange}
                >
                  {months.map(d => {
                    return (
                      <MenuItem value={d}>{d}</MenuItem>
                    )
                    
                  })}
                </Select>
              </FormControl>
            </Box>
            {month 
              ?
                <div>
             
                {
                  days.map(r => {
                  return (
                    <>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>{month + " " + r}</Paper>
                    </Grid>
                    <div style={{"margin-top": "15px"}}>
                      <TextField id="outlined-basic" label="Manhours" variant="outlined" />
                      <TextField id="outlined-basic" label="OT" variant="outlined" />
                      <TextField id="outlined-basic" label="Late" variant="outlined" />
                    </div>

                    </>
                  )
                  })       
                }
                </div>
              :
                ''
            }
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleArchive} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  )
}
