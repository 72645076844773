// material
import { Grid, Box, Button } from '@material-ui/core'
import {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/styles';
import Timein from "../../assets/svg/TIMEIN.svg"
const useStyles = makeStyles((theme) => ({
  circularBorder: {
    width: '100px',           // Set the width of your circular border
    height: '100px',          // Set the height of your circular border
    borderRadius: '50%',      // Makes the border circular
    border: '2px solid red',  // Adjust the color and thickness of the border
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default function TimeIn({ request, current_status, width, location }) {
    const handleStatusClick = async (e, status) => {
        e.preventDefault()
        request(status)
    }
    
    const classes = useStyles();

    return (
        <Button
            fullWidth
            size="medium"
            variant="contained"
            onClick={(e) => handleStatusClick(e, 'Time in')}
            sx={ { 
                borderRadius: 28, 
                backgroundColor:current_status === 'Time in'?'#878B8B!important': '#1F98E8',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)!important',
                color:'white!important',
                width:'150px'
            }}
            disabled={current_status === 'Time in'}
        >
            Time in
        </Button>
        /*<>
            <Grid item xs={12}>
                <Box marginTop={3} sx={{ display: 'block', width: {width} }}>
                    <Box>
                        <iframe 
                            title="resumeIframe"
                            src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
                            width="100%" 
                            height="280" 
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            allowfullscreen=""
                            key={`landmark-${location.latitude}`} 
                            >
                        </iframe>
                        {width === '215px' 
                            ?
                                <h4 style={{"margin-left": '45px'}}>Current Location</h4>    
                            :
                                <h4 style={{"margin-left": '80px'}}>Current Location</h4>
                        }
                        
                    </Box>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={(e) => handleStatusClick(e, 'Time in')}
                        sx={ { borderRadius: 28 } }
                    >
                        Time in
                    </Button>
                </Box>
            </Grid>
        </>*/


    )
}