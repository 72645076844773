import { filter } from 'lodash'
import { useEffect, useState, useRef } from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  FormControl,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  useTheme,
  useMediaQuery
} from '@material-ui/core'

// icon
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'

// components
import archiveOutline from '@iconify/icons-eva/archive-outline'
import { Link as RouterLink } from 'react-router-dom'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenuUpdate } from '../components/_dashboard/user'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingScreen from 'components/LoadingScreen'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'
import QRCode from "qrcode.react"
import { useSnackbar } from 'notistack5'
import { capitalCase } from 'capital-case'
import { CSVLink } from 'react-csv'
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@material-ui/icons/GetApp'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  justifyContent: 'center' 
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.empName ? _user.empName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

function applySortFilterSchedule(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.date ? _user.date : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function User() {
  const ref = useRef(null)
  const getTodayDateOnly = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  };
  const today = new Date();

  // Create a date object for yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [reports, setReports] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [month, setMonth] = useState();
  const [users, setUser] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [action, setAction] = useState('')
  const [date, setDate] = useState([])
  const d = new Date();
  const [fromDate, setFromDate] = useState(dayjs(yesterday).format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(dayjs(yesterday).format('YYYY-MM-DD'))
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()
      if (!local_user) return

      const user = JSON.parse(local_user)
      setUser(user)
    }

    load()
  }, [])

  const handleChangeFromDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setFromDate(dateOnly)

     // Date Validation
    if (dateOnly && toDate) {
      const from = dayjs(dateOnly);
      const to = dayjs(toDate);
      const diffInDays = to.diff(from, 'day');

      if (diffInDays > 15) {
        alert('Date range must not exceed 16 days');
        setFromDate(null); 
        setToDate(null)
      }
    }
  }

  const handleChangeToDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setToDate(dateOnly)

    if (dateOnly && fromDate) {
      const from = dayjs(fromDate);
      const to = dayjs(dateOnly);
      const diffInDays = to.diff(from, 'day');

      if (diffInDays > 15) {
        alert('Date range must not exceed 16 days');
        setToDate(null);
        setFromDate(null)
      }
    }
  }

  /*const handleGenerateBreaklist = async () => {
    setLoading(true)
    if (fromDate > toDate) {
      alert("From date must be before or equal to end date")
    }
    else {
      const data = {
        "from": fromDate,
        "to": toDate,
        "store": user.company
      }
      const result = await userAPI.get_breaklist(data)
      console.log(result)
      if(result.data.success) {
        setUsers(result.data.data) 
        setresultRequest(JSON.parse(result.config.data))
        setLoading(false)
      }
      else {
        setUsers([]) 
        alert("Invalid Dates. Breaklist date already submitted and saved.")
        setLoading(false)
      }  
    }
    
    setLoading(false)
  }*/

  const isLeapYear = (year) => {
    return moment([year, 1, 29]).isValid(); // Checking if February 29th of the given year is valid
  };

  const handleOpen = async () => {
  	
  	const date = new Date(fromDate);
  	const monthName = date.toLocaleString('default', { month: 'long' });
  	var daysOfYear = []

  	for (var d = new Date(fromDate); d <= new Date(toDate); d.setDate(d.getDate() + 1)) {
  	  daysOfYear.push(dayjs(d).format('YYYY-MM-DD'))
  	}
  	console.log(monthName)
  	console.log(toDate)
  	setLoading(true)
  	let item
  	let chunked = []
  	item = await userAPI.get_user_records_range_v2(users._id, fromDate, toDate)
  	console.log(item.data.data)
  	while (item.data.l !== item.data.data.length) {
  	  item = await userAPI.get_user_records_range_v2(users._id, fromDate, toDate)
  	}
  	if (item.status !== 200) {
  	  alert("Data cannot be loaded due to traffic of requests please refresh the page and try again")
  	  window.parent.location = window.parent.location.href
  	}
  	else {
  	  let size = daysOfYear.length;
  	  for (let i = 0; i < item.data.data.length; i += size) {
  	    chunked.push(item.data.data.slice(i, i + size))
  	  }
  	}
  	setMonth(monthName)
  	setDate(daysOfYear)
  	setReports(chunked)
  	setOpen(true);
  	setLoading(false)
    /*setLoading(true)*/
   /* let year = new Date().getFullYear()
    if (selectedYear !== year) {
      year = selectedYear
    }
    let monthNo = e.target.getAttribute('month')
    let month = e.target.getAttribute('id')
    let start_date = fromDate
    let end_date 
  
    var daysOfYear = []

    for (var d = new Date(start_date); d <= new Date(end_date); d.setDate(d.getDate() + 1)) {
      daysOfYear.push(dayjs(d).format('YYYY-MM-DD'))
    }
    let item
    let chunked = []
    if (month === "1stHalf") {
      item = await userAPI.get_user_records_range_v2(users._id, fromDate, toDate)
      while (item.data.l !== item.data.data.length) {
        item = await userAPI.get_user_records_range_v2(users._id, start_date, end_date)
      }
      if (item.status !== 200) {
        alert("Data cannot be loaded due to traffic of requests please refresh the page and try again")
        window.parent.location = window.parent.location.href
      }

      else {

        let size = daysOfYear.length;
        for (let i = 0; i < item.data.data.length; i += size) {
          chunked.push(item.data.data.slice(i, i + size))
        }
      }
    }
    if (month === "2ndHalf") {
      item = await userAPI.get_user_records_range_v2(users._id, start_date, end_date)
      while (item.data.l !== item.data.data.length) {
        item = await userAPI.get_user_records_range_v2(users._id, start_date, end_date)
      }
      if (item.status !== 200) {
        alert("Data cannot be loaded due to traffic of requests please refresh the page and try again")
        window.parent.location = window.parent.location.href
      }
      else {
        let size = daysOfYear.length;
        for (let i = 0; i < item.data.data.length; i += size) {
          chunked.push(item.data.data.slice(i, i + size))
        }
      }
    }
    setMonth(e.target.getAttribute('value'))
    setMonthName(month)
    setDate(daysOfYear)
    setReports(chunked)
    setOpen(true);
    setLoading(false)
    setProcessing(false)*/
  }

  const handleClose = () => {
    setOpen(false);
  }

  const getCsvData = () => {

    const csvData = []
    if (!reports) return enqueueSnackbar('Unable to download reports')
    csvData.push([`Store: `, `${users.company}`])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['Name', 'Date', 'time-in', 'Time-in Location', 'Break in', 'Break-in Location', 'Break-out', 'Break-out Location', 'Time out', 'Time-out Location'])
    reports.map((v, k) => {
      v.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date);
      });
      v.map((e, i) => {

        if (!e.reports[0] || e.reports === null) {
          if (e.Employee.displayName === null || e.Employee.firstName === null || e.Employee.lastName === null) {
            csvData.push([
              `${'NULL'}`,
              `${e.date}`,
              `-`,
              `-`,
              `-`,
              `-`,
              '-',
              `-`,
              `-`,
              `-`,
            ])
          }
          else {
            if (i === 0 || i === v.length + 1) {
              csvData.push([
                `${capitalCase(e.Employee.lastName + " " + e.Employee.firstName)}`,
                `${e.date}`,
                `-`,
                `-`,
                `-`,
                `-`,
                '-',
                `-`,
                `-`,
                `-`,
              ])
            }
            else {
              csvData.push([
                `${''}`,
                `${e.date}`,
                `-`,
                `-`,
                `-`,
                `-`,
                '-',
                `-`,
                `-`,
                `-`,
              ])
            }

          }
        }
        else {
          if (e.Employee.displayName === null || e.Employee.firstName === null || e.Employee.lastName === null) {
            csvData.push([
              `${capitalCase(' ')}`,
              `${e.date}`,
              `${filterByStatus(e.reports[0].record, 'time-in').time} - ${filterByStatus(e.reports[0].record, 'time-in').workmate} `,
              `${filterByStatus(e.reports[0].record, 'time-in')._latitude} ${filterByStatus(e.reports[0].record, 'time-in')._longitude}`,
              `${filterByStatus(e.reports[0].record, 'break-in').time} - ${filterByStatus(e.reports[0].record, 'break-in').workmate} `,
              `${filterByStatus(e.reports[0].record, 'break-in')._latitude} ${filterByStatus(e.reports[0].record, 'break-in')._longitude}`,
              `${filterByStatus(e.reports[0].record, 'break-out').time} - ${filterByStatus(e.reports[0].record, 'break-out').workmate} `,
              `${filterByStatus(e.reports[0].record, 'break-out')._latitude} ${filterByStatus(e.reports[0].record, 'break-out')._longitude}`,
              `${filterByStatus(e.reports[0].record, 'time-out').time} - ${filterByStatus(e.reports[0].record, 'time-out').workmate} `,
              `${filterByStatus(e.reports[0].record, 'time-out')._latitude} ${filterByStatus(e.reports[0].record, 'time-out')._longitude}`,

            ])
          }
          else {
            if (i === 0 || i === v.length + 1) {
              csvData.push([
                `${capitalCase(e.Employee.lastName + " " + e.Employee.firstName)}`,
                `${e.date}`,
                `${filterByStatus(e.reports[0].record, 'time-in').time} - ${filterByStatus(e.reports[0].record, 'time-in').workmate} `,
                `${filterByStatus(e.reports[0].record, 'time-in')._latitude} ${filterByStatus(e.reports[0].record, 'time-in')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'break-in').time} - ${filterByStatus(e.reports[0].record, 'break-in').workmate} `,
                `${filterByStatus(e.reports[0].record, 'break-in')._latitude} ${filterByStatus(e.reports[0].record, 'break-in')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'break-out').time} - ${filterByStatus(e.reports[0].record, 'break-out').workmate} `,
                `${filterByStatus(e.reports[0].record, 'break-out')._latitude} ${filterByStatus(e.reports[0].record, 'break-out')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'time-out').time} - ${filterByStatus(e.reports[0].record, 'time-out').workmate}`,
                `${filterByStatus(e.reports[0].record, 'time-out')._latitude} ${filterByStatus(e.reports[0].record, 'time-out')._longitude}`,
              ])
            }
            else {
              csvData.push([
                `${capitalCase(' ')}`,
                `${e.date}`,
                `${filterByStatus(e.reports[0].record, 'time-in').time} - ${filterByStatus(e.reports[0].record, 'time-in').workmate} `,
                `${filterByStatus(e.reports[0].record, 'time-in')._latitude} ${filterByStatus(e.reports[0].record, 'time-in')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'break-in').time} - ${filterByStatus(e.reports[0].record, 'break-in').workmate} `,
                `${filterByStatus(e.reports[0].record, 'break-in')._latitude} ${filterByStatus(e.reports[0].record, 'break-in')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'break-out').time} - ${filterByStatus(e.reports[0].record, 'break-out').workmate} `,
                `${filterByStatus(e.reports[0].record, 'break-out')._latitude} ${filterByStatus(e.reports[0].record, 'break-out')._longitude}`,
                `${filterByStatus(e.reports[0].record, 'time-out').time} - ${filterByStatus(e.reports[0].record, 'time-out').workmate}`,
                `${filterByStatus(e.reports[0].record, 'time-out')._latitude} ${filterByStatus(e.reports[0].record, 'time-out')._longitude}`,
              ])
            }


          }
        }
      })
      csvData.push(['', '', '', '', '', ''])
    })

    return csvData
  }

  const renderTime = (_time) => {
    let _date = new Date(_time)
    /*    var hours = _date.getHours()
        var minutes = _date.getMinutes()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        const phTime = moment.tz(strTime, 'h:mm A', 'Asia/Manila')*/
    const userTimeZone = moment.tz.guess()
    const userLocalTime = _date.toLocaleString('en-US', { timeZone: userTimeZone, hour: 'numeric', minute: '2-digit' });
    return userLocalTime
    //return strTime
  }

  const filterByStatus = (_data, type) => {
    let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
    if (_d.length > 0) {
      _d = _d[0]
    }
    if (_d) {
      if (typeof (_d.time) === "string") {
        return {
          time: _d.time === undefined ? 'n/a' : _d.time,
          _longitude: _d.location === undefined ? '' : _d.location.longitude,
          _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
      else {
        return {
          time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
          _longitude: _d.location === undefined ? '' : _d.location.longitude,
          _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
    } else {
      return '-'
    }
  }
  return (
    <Page title="Breaklist | Time In">
      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>
        {isLoading 
          ? 
            <Box sx={{ height: '50vh' }}>
              <LoadingScreen />
            </Box>
          :
            <Card>
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ 
                    mt: 2.5,
                    ml: isMobile ? 0 : 2.5,
                    width: isMobile ? '100%': "75%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="From"
                        value={fromDate}
                        minDate={new Date('2017-01-01')}
                        maxDate={yesterday}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date:Date) => handleChangeFromDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ 
                     mt: 2.5,
                     ml: isMobile ? 0 : 2.5,
                     mb: 2.5,
                     width: isMobile ? '100%': "75%"
                   }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="To"
                        value={toDate}
                        minDate={new Date('2017-01-01')}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date:Date) => handleChangeToDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ 
                     mt: 3.5,
                     ml: isMobile ? 0 : 2.5,
                     width: isMobile ? '100%': "75%"
                   }}>
                    <Button
                      variant="contained"
                      to="#"
                      onClick={handleOpen}
                    >
                      Export
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>

        }
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {month} Reports
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <CSVLink
              filename={`${month} Record Log.csv`}
              data={getCsvData()}
              style={{
                display: 'flex',
                padding: '0.5 1rem',
                width: '200px',
              }}
            >
              <GetAppIcon sx={{ mr: 1 }} /> <Typography variant="p">Export</Typography>
            </CSVLink>
          </Typography>
        </Box>
      </Modal>
    </Page>
  )
}
