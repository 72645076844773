// NotificationDialog.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@material-ui/core';

const NotificationDialog = ({ open, onClose, toggle, onToggle }) => {
  const handleClose = () => {
    onClose();
  };

  const handleToggle = (event) => {
    onToggle(event.target.checked)
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Schedule notification Preference</DialogTitle>
      <DialogContent>
        <Typography>Would you like to be notified when your schedule is approaching or has ended?</Typography>
        <FormControlLabel
          control={<Switch checked={toggle} onChange={handleToggle} />}
          label="notify me"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
