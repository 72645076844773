// material
import { Box } from '@material-ui/core';
import BreakInTrue from '../../assets/svg/breakTime1.png';
import TimeInOut from '../../assets/svg/tIntOut.png';
import TimeInOutUnable from '../../assets/svg/unabletimeInOut.png'
export default function BreakSwitch({ current_status, handleSwitchTimeBreak }) {
  return (
    <Box
      sx={{
        marginTop: '25px',
        mb:'5vh',
        display: 'flex',
        justifyContent: 'center',
        // cursor: 'pointer', // Adding cursor: 'pointer' to indicate that it's clickable
        cursor: current_status === 'Time in' || current_status === 'Break out' ? 'pointer' : 'default', // Conditionally setting cursor style
      }}
      onClick={() => {
        if (current_status === 'Break out') {
          handleSwitchTimeBreak(false);
        } else {
          handleSwitchTimeBreak(true);
        }
      }}
    >
      <div style={{paddingLeft:'2.5rem'}}>
      {current_status === 'Break in' || current_status === 'Break out' ? (
        current_status === 'Break in'?  <img src={TimeInOutUnable} alt="Break In/Out" />:  <img src={TimeInOut} alt="Break In/Out" />
      ) : (
        <img src={BreakInTrue} alt="Break In True" />
      )}
      </div>
    </Box>
  );
}
