// material
import { Grid, Box, Button } from '@material-ui/core'
import {useEffect, useState} from 'react'
import BREAKOUT from "../../assets/svg/BREAKOUT.svg"
// ----------------------------------------------------------------------

export default function BreakOut({ request, current_status, width, location }) {
    const handleStatusClick = async (e, status) => {
        e.preventDefault()
        request(status)
    }

    return (
        <Button
            variant="outlined"
            onClick={(e) => handleStatusClick(e, 'Break out')}
            sx={ { 
                borderRadius: 28,
                borderColor: current_status==='Break out'? '#878B8B': '#1F98E8', 
                backgroundColor: current_status==='Break out'? ' #878B8B': '#1F98E8',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)',
                color:'white!important', 
                width: "150px"} }
            disabled={current_status === 'Break out'}
        >
            Break out
        </Button>
        // <>
        //     <Grid item xs={12}>
        //         <Box marginTop={3} sx={{ display: 'block', width: {width} }}>
        //             {current_status === 'Break in' ? (
        //                 <Box>
        //                     <iframe 
        //                         title="resumeIframe"
        //                         src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
        //                         width="100%" 
        //                         height="280" 
        //                         frameBorder="0"
        //                         scrolling="no"
        //                         marginHeight="0"
        //                         marginWidth="0"
        //                         allowfullscreen=""
        //                         key={`landmark-${location.latitude}`} 
        //                         >
        //                     </iframe>
        //                     {width === '215px' 
        //                         ?
        //                             <h4 style={{"margin-left": '45px'}}>Current Location</h4>    
        //                         :
        //                             <h4 style={{"margin-left": '80px'}}>Current Location</h4>
        //                     }
        //                 </Box>
        //             ) : (
        //                 ''
        //             )}
        //             <Button
        //                 fullWidth
        //                 size="large"
        //                 variant="outlined"
        //                 style={{ borderColor: '#34495e', color: '#34495e' }}
        //                 onClick={(e) => handleStatusClick(e, 'Break out')}
        //                 sx={ { borderRadius: 28 } }
        //             >
        //                 Break out
        //             </Button>
        //         </Box>
        //     </Grid>
        // </>
    )
}
