import { Card, CardContent, Typography, Avatar, Stack, Button, Box, Grid } from '@material-ui/core';
import Label from './Label';
import UserMoreMenuRecord from './_dashboard/user/UserMoreMenuRecord';

export default function UserCard({ user, handleAction }) {
  const { _id, firstName, displayName, lastName, email, phone, role, isOnBoarded, company, image, isVerified } = user;
  let name = `${firstName} ${lastName}`;
  if (firstName === null || lastName === null) {
    name = displayName;
  }

  return (
    <Card key={_id} sx={{ mb: 2, p: 2 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Avatar alt={name} src={image} sx={{ width: 56, height: 56 }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6" component="div">
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {email}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {company}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} container direction="row" justifyContent="space-between">
            <Label variant="ghost" color={'info'}>
              Role: {role === 1 ? 'Store' : 'Employee'}
            </Label>
            <Label variant="ghost">
              Verified: {isVerified ? 'Yes' : 'No'}
            </Label>
            <Label variant="ghost" color={!isOnBoarded ? 'error' : 'success'}>
              Onboarded: {isOnBoarded ? 'Complete' : 'Incomplete'}
            </Label>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between">
          <UserMoreMenuRecord id={_id} action={handleAction} />
        </Box>
      </CardContent>
    </Card>
  );
}
