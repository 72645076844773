import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  TextField,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  CardContent
} from '@material-ui/core';
import { KeyboardArrowUp as KeyboardArrowUpIcon, Sort as SortIcon  } from '@material-ui/icons';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import UserCard2 from '../components/UserCard2';
import { format, isBefore, subDays } from 'date-fns';
import userAPI from 'utils/api/users';
import storage from 'utils/storage';
import Bugsnag from '@bugsnag/js';
import QRCode from "qrcode.react";
import html2canvas from 'html2canvas';

const TABLE_HEAD = [
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'email', label: 'Email address', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'createdAt', label: 'Date Created', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => (_user.company ? _user.company.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''));
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [action, setAction] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const load = async () => {
    const local_user = await storage.getUser();
    if (!local_user) return;

    const user = JSON.parse(local_user);
    const result = await userAPI.get_new_store(user._id);
    if (!result.ok) {
      Bugsnag.notify(result);
      return;
    }
    setUsers(result.data.data);
  };
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    load();
  }, [action]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClose = () => setOpen(false);


  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const isUserNotFound = filteredUsers.length === 0;

  const handleSortMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (property) => {
    handleRequestSort(null, property);
    handleSortMenuClose();
  };

  const handleApprove = async (e) => {
  	setIsLoading(true)
  	alert("Processing approval")
    const id = e.target.value
    const result = await userAPI.approve_new_store(id)
    if(result.status === 200) {
    	alert("Store account activated!")
    }
    else {
    	alert("Something went wrong please try again later!")
    }
    load()
    setIsLoading(false)
  };

  const handleDecline = async (e) => {
    setIsLoading(true)
    alert("Processing decline")
    const id = e.target.value
    const result = await userAPI.decline_new_store(id)
    if(result.status === 200) {
      alert("Store account activation decline!")
    }
    else {
      alert("Something went wrong please try again later!")
    }
    load()
    setIsLoading(false)
  };


  return (
    <Page title="Store Approval | Time In">
      
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Store Approval
          </Typography>
        </Stack>


        {isMobile ? (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <TextField
              fullWidth
              label="Search store"
              value={filterName}
              onChange={handleFilterByName}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="subtitle2">
              {filteredUsers.length} item(s)
            </Typography>
            <IconButton onClick={handleSortMenuOpen}>
              <SortIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleSortMenuClose}
            >
              {TABLE_HEAD.map((headCell) => (
                <MenuItem key={headCell.id} onClick={() => handleSortChange(headCell.id)}>
                  Sort by {headCell.label}
                </MenuItem>
              ))}
            </Menu>
        </Stack>
            {filteredUsers.map((user) => (
              <Card key={user._id} sx={{ mb: 2 }}>
                <CardContent>
                  <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h6" component="div">
                      {user.company}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.secondary">
                    {user.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.phone}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.createdAt}
                  </Typography>
                  <Box mt={2} display="flex" justifyContent="flex-start">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => { handleApprove(e) }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ marginLeft: '10px' }}
                      onClick={(e) => { handleDecline(e) }}
                    >
                      Decline
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </>
        ) : (
          <Card sx={{ width: '100%' }}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ width: '100%' }}>
                {users ? (
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody sx={{ width: '3000px' }}>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const {
                          _id,
                          email,
                          phone,
                          company,
                          image,
                          createdAt
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar sx={{ ml: 2 }} alt={company} src={image} className="ml-3" />
                                <Typography variant="subtitle2" noWrap>
                                  {company}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{createdAt}</TableCell>
                            <TableCell align="left">
                              <Button 
                                size="small" 
                                id={_id} 
                                value={_id} 
                                onClick={(e) => { handleApprove(e) }} 
                                disabled={isLoading} 
                                style={{ 
                                  backgroundColor: isLoading ? 'lightgray' : 'blue',
                                  color: 'white',
                                  marginRight: '10px' 
                                }}
                              >
                                {isLoading ? 'Processing Approval' : 'Approve'}
                              </Button>
                              <Button 
                                size="small" 
                                id={_id} 
                                value={_id} 
                                onClick={(e) => { handleDecline(e) }} 
                                disabled={isLoading} 
                                style={{ 
                                  backgroundColor: isLoading ? 'lightgray' : 'red',
                                  color: 'white' 
                                }}
                              >
                                {isLoading ? 'Processing Decline' : 'Decline'}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                ) : (
                  ''
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Rows per page"}
            />
          </Card>
        )}

<Fab 
  color="primary" 
  aria-label="scroll back to top" 
  sx={{ 
    position: 'fixed', 
    bottom: 26, 
    left: '50%', 
    transform: 'translateX(-50%)', 
    opacity : 0.8
  }} 
  onClick={() => window.scrollTo(0, 0)}
>
  <KeyboardArrowUpIcon />
</Fab>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div id="qr-gen">
                <Typography variant="h6" gutterBottom sx={{ textAlign: 'center'}}>
                  {`${name}`}
                </Typography>
                <Grid container sx={{ pl: 2 }}>
                  <QRCode
                    size={300}
                    value={id}
                  />
                </Grid>
              </div>
              
              {/*<Button variant="contained" color="success" onClick={downloadQRCode} sx={{ mt: 2, px: 17 }}>
                {`Print QR for ${name}`}
              </Button>*/}
            </Box>
          </Fade>
        </Modal>

    </Page>
  );
}